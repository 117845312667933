/* Publicidad.css */

/* Estilo para las flechas del carrusel */
  .ant-carousel .slick-arrow {
    width: 40px;
    height: 40px;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para las flechas */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Asegúrate de que las flechas estén por encima de las imágenes */
  }
  
  .ant-carousel .slick-prev {
    left: 10px; /* Ajusta la posición según sea necesario */
  }
  
  .ant-carousel .slick-next {
    right: 10px; /* Ajusta la posición según sea necesario */
  }
  
  .ant-carousel .slick-arrow::before {
    font-size: 24px;
    color: rgb(3, 3, 3); /* Color de las flechas */
  }
  
  @media only screen and (min-width: 0px) and (max-width:920px) {
    .imagePublitity{
      width: 100vw !important;
      height: 50vh !important;
      margin: 1rem !important;
    }


    .titleRibbon {
      font-size: .8rem !important;
    }
    .subtitleRibbon{
      font-size: .5rem !important;
    }
  
    .ribbon {
      margin-left: 1rem;
      margin-right: 1rem;
      margin-top: 1rem;
      padding: 5px 20px;
      font-size: 8px;
    }
  
    .ribbon:before,
    .ribbon:after {
      border-width: 1em;
    }
    .containerPublicty{
      margin-top: 8rem;
    }
  }




  
  .ribbon{
    /* POSITIONS */
    position: relative;
    
    /* BOX MODEL */
    display: block;
    margin-left: 2em;
    margin-right: 2em;
    margin-top: .5rem;

    padding: 10px 30px;
    
    /* OTROS */
    background-color: #003865;
    box-shadow: 0px 2px 45px rgba(0,0,0,0.55);
    border-radius: 3px;
    font-size: 32px;
    text-align: center;
    clear: both;
  }
  
  .ribbon:before{
    /* POSITION */
    position: absolute;
    left: -1.5em;
    bottom: 3px;
    z-index: -10;
    
    /* BOX MODEL */
    display: block;
    width: 10%;
    height: 0;
    border-color: #003865 #003865 #003865 transparent;
    border-style: solid;
    border-width: 1.2em;
    
    /* OTROS */
    content: ''; 
  }
  
  .ribbon:after{
    /* POSITION */
    position: absolute;
    right: -1.5em;
    bottom: 3px;
    z-index: -10;
    
    /* BOX MODEL */
    display: block;
    width: 10%;
    height: 0;
    border-color: #003865  transparent #003865 #003865;
    border-style: solid;
    border-width: 1.2em;
    
    /* OTROS */
    content: '';  
  }
  